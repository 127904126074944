<template>
  <vx-card title="Customer Visit">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Sales</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedSales"
          :options="optionSales"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          placeholder="Type to search"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          :inline="false"
          v-model="date"
          placeholder="Select Date"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Status</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedStatus"
          :options="optionStatus"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="false"
          placeholder="Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option }} </span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <vs-button
      class="mt-2"
      color="success"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleOpen()"
      >Generate</vs-button
    >
    <div class="vx-row">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-col sm:w-1/1 w-full mb-base">
          <data-table
            :salesPersonalID="this.selectedSales.id"
            :date="this.date"
            :status="this.selectedStatus"
            :baseUrl="this.baseUrl"
            :detail="this.detail"
          ></data-table>
        </div>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan>
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";
import FormPlan from "./Form.vue";
export default {
  components: {
    DataTable,
    Datepicker,
    FormPlan,
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/v1/sales-force-automation/customer-visit",
      date: null,
      optionStatus: ["Pending", "Ongoing", "Visited", "Non-visited"],
      selectedStatus: "",
      optionSales: [],
      selectedSales: "",
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    getDatSales() {
      this.$vs.loading();
      this.$http
        .get(
          "/api/v1/sales-force-automation/customer-visit-plan/datatable-sales",
          {
            params: {
              length: 0,
              // search: this.table.search,
              order: "name",
              sort: "asc",
            },
          }
        )
        .then((resp) => {
          if (resp.status == "success") {
            this.optionSales = resp.data.records;
            if (this.optionSales.length > 0) {
              this.selectedSales = this.optionSales[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.getDatSales();
  },
  watch: {
    date(val) {
      console.log(val);
    },
    selectedSales(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>

