<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Sales</label>
        <multiselect
          name="sales"
          class="selectExample"
          v-model="salesSelected"
          :options="salesOptions"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          v-validate="'required'"
          placeholder="Type to search"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('sales')">{{
          errors.first("sales")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Date</label>
        <datepicker
          name="date"
          :inline="false"
          v-model="date"
          placeholder="Select Date"
          v-validate="'required'"
        ></datepicker>
        <span class="text-danger text-sm" v-show="errors.has('date')">{{
          errors.first("date")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    baseUrl: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        date: null,
        salesOptions: [],
        salesSelected: [],
      };
    },
    getDatSales() {
      this.$vs.loading();
      this.$http
        .get(
          "/api/v1/sales-force-automation/customer-visit-plan/datatable-sales",
          {
            params: {
              length: 0,
              // search: this.table.search,
              order: "name",
              sort: "asc",
            },
          }
        )
        .then((resp) => {
          if (resp.status == "success") {
            this.salesOptions = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$http
            .post(this.baseUrl, {
              date: moment(this.date).format("YYYY-MM-DD HH:mm:ss"),
              sales_personal_id: this.salesSelected.id,
            })
            .then((resp) => {
              this.$vs.loading.close();
              if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "Data Generated",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
  },
  mounted() {
    this.getDatSales();
  },
  computed: {},
  watch: {},
};
</script>
